import { forwardRef, type ForwardedRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material'

import { body2 } from '../../../theme/Typography/styles'
import { FILTER_BOX_SHADOW } from '../../../theme/elevation'
import SearchIcon from './../../../assets/icons/search-icon.svg?react'

export type Variant = 'outlined' | 'primary'
export type SearchInputProps = Omit<
  TextFieldProps,
  'onChange' | 'variant' | 'ref'
> & {
  onChange?: (value: string) => void
  onReset?: () => void
  variant?: Variant
}

const SearchInput = forwardRef(
  (
    {
      InputProps,
      disabled,
      inputProps,
      onChange,
      onReset,
      placeholder = 'Search',
      sx,
      value,
      variant,
      ...rest
    }: SearchInputProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme()

    const reset = () => {
      onChange?.('')
      onReset?.()
    }
    const isOutlined = variant === 'outlined'
    return (
      <TextField
        InputProps={{
          ...InputProps,
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                onClick={reset}
                sx={{
                  fontSize: 16,
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                color={theme.palette.grey[500]}
                sx={{ fontSize: 16 }}
              />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '3vmin',
          },
        }}
        disabled={disabled}
        inputProps={{
          ...inputProps,
          disabled,
        }}
        onChange={(event) => {
          onChange?.(event.target.value)
        }}
        placeholder={placeholder}
        ref={ref}
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          borderRadius: '3vmin',
          boxShadow: isOutlined ? 'none' : FILTER_BOX_SHADOW,
          maxWidth: 280,
          width: 1,
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '& fieldset': {
            border: (theme) =>
              isOutlined ? `1px solid ${theme.palette.grey[50]}` : 'none',
          },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '.MuiInputBase-root.MuiInputBase-root': {
            px: 2,
            py: 0,
          },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '.MuiInputBase-input.MuiInputBase-input': {
            py: '8.5px',
          },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            color: (theme) => theme.palette.grey[500],
            ...body2,
          },
          // to override the default MUI styles https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          ':hover .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline':
            {
              borderColor: (theme) => theme.palette.grey[50],
            },
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline':
              {
                borderColor: (theme) => theme.palette.grey[50],
                borderWidth: 1,
              },
            '.MuiInputAdornment-root.MuiInputAdornment-root': {
              color: (theme) => theme.palette.primary.main,
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.grey[50],
          },
          ...sx,
        }}
        value={value}
        {...rest}
      />
    )
  }
)

export default SearchInput
