import { useCallback } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import dayjs from 'dayjs'
import { User } from 'firebase/auth'

import { InvitationStatus, type Invitation } from '@/models/hasura/Invitation'

import { Role } from '../../../models/hasura/User'
import {
  useGetInvitationQuery,
  useUpdateInvitationMutation,
  //useUpdateInvitationMutation,
} from '../../../services/hasura/invitations'
import { useInsertOrganizationMutation } from '../../../services/hasura/organizations'
import { useInsertUserMutation } from '../../../services/hasura/users'

/*

  Cases:

  1. Invitation
   - Insert user with organization id from invitation
  2. No invitation
  - Create organization
  - Insert user with created organization id
  
*/

type useSignUpProps = {
  invitationId: string | null
}

const useSignUp = ({ invitationId }: useSignUpProps) => {
  const [insertUser, { data: insertUserData, status: insertUserStatus }] =
    useInsertUserMutation()

  const [insertOrganization, { status: insertOrganizationStatus }] =
    useInsertOrganizationMutation()

  const { data: invitation, isLoading: isInvitationLoading } =
    useGetInvitationQuery(invitationId || skipToken, {
      skip: !invitationId,
    })

  const [updateInvitation] = useUpdateInvitationMutation()

  const insertUserWithOrganization = useCallback(
    async (user: User) => {
      const organization = await insertOrganization().unwrap()
      await insertUser({
        firebase_uid: user.uid,
        role: Role.owner,
        organization_id: organization.id,
      })
    },
    [insertOrganization, insertUser]
  )

  const insertUserWithInvitation = useCallback(
    async (user: User, invitation: Invitation) => {
      await insertUser({
        firebase_uid: user.uid,
        role: invitation.role,
        organization_id: invitation.organizationId,
        agent_id: invitation.agentId,
        name: invitation.name || '',
        picture: invitation.picture,
      })
      await updateInvitation({
        id: invitation.id,
        status: InvitationStatus.accepted,
        accepted_date: new Date().toISOString(),
        updated_date: new Date().toISOString(),
      })
    },
    [insertUser, updateInvitation]
  )

  const handleUserInsert = useCallback(
    (user: User) => {
      if (invitation) {
        const isExpired = invitation.expiresDate
          ? dayjs().isAfter(dayjs(invitation.expiresDate))
          : false

        if (isExpired) {
          throw new Error('Invitation has expired')
        }
        return invitation.status === InvitationStatus.pending
          ? insertUserWithInvitation(user, invitation)
          : null
      }
      return insertUserWithOrganization(user)
    },
    [insertUserWithInvitation, insertUserWithOrganization, invitation]
  )

  return {
    insertUser,
    insertUserData,
    insertUserStatus,
    invitation,
    isInvitationLoading,
    handleUserInsert,
    insertOrganizationStatus,
  }
}

export default useSignUp
