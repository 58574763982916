import { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment } from '@mui/material'

import LoginTextField from './LoginTextField'

type LoginPasswordFieldProps = {
  helperText?: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const LoginPasswordField = ({
  helperText,
  label,
  onChange,
  value,
}: LoginPasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <LoginTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={helperText}
      label={label}
      onChange={onChange}
      placeholder="••••••••"
      type={showPassword ? 'text' : 'password'}
      value={value}
    />
  )
}

export default LoginPasswordField
