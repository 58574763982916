import { useState } from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Container, IconButton, Paper, Stack } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { SHOW_MOBILE_WARNING_HELP } from '..'
import MobileWarning from '../components/Login/MobileWarning'
import Logo1 from '../logos/Logo1'
import LogoTextBlack from '../logos/LogoTextBlack'
import { isMobile } from '../modules/dom/dom'
import { PAGE_MODAL_SURFACE } from '../theme/surfaces'

const MobileWarningProtectedRoutes = () => {
  const [isWarning, setIsWarning] = useState(false ?? isMobile())

  return isWarning ? (
    <Paper
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          justifyContent: 'space-between',
          minHeight: '100vh',
          p: 3,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mt={5.5}
          spacing={1.125}
        >
          <Logo1 />
          <LogoTextBlack />
        </Stack>
        <MobileWarning onClose={() => setIsWarning(false)} sx={{ flex: 1 }} />
        <Stack alignItems="flex-end" flex="none" mt="auto" width={1}>
          {SHOW_MOBILE_WARNING_HELP && (
            <IconButton
              sx={{
                background: PAGE_MODAL_SURFACE,
                boxShadow: (theme) =>
                  `inset 0 0 0 1px ${theme.palette.grey[50]}`,
              }}
            >
              <QuestionMarkIcon />
            </IconButton>
          )}
        </Stack>
      </Container>
    </Paper>
  ) : (
    <Outlet />
  )
}

export default MobileWarningProtectedRoutes
